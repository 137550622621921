/* @media only screen and (max-width: 600px) {
    .carousel {
        width: 300px;
        height: 200px;
    }
} */

.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555);
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 6rem;
    top: 85%;
    z-index: 3;
}

.arrow-right {
    right: 6rem;
    top: 85%;
}

.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 400px;
}

.slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #808080;
    width: 100%;
    height: 100%;
}

.card {
    background-color: white;
}

.card-hidden {
    display: none !important;
}

.slide-hidden {
    display: none !important;
}


.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer
}

.indicator-inactive {
    background-color: grey;
}

@media only screen and (max-width: 600px) {
    .carousel {
        width: 300px;
        height: 200px;
    }
}

@media only screen and (max-width: 600px) {
    .arrow-left, .arrow-right {
        top: 120%; /* adjust this value as needed */
    }
}