/***** Always True *****/
@media only screen {
}

/***** > 1000px *****/
@media only screen and (min-width: 1000px) {
    .respond-min {
        display: none !important;
    }
    .respond-contact {
        width: 540px
    }
    .respond-card {
        width: 90%;
    }
    .respond-resource {
        width: "45%"
    }
}

@media (max-width: 1000px) {
    .mobile-margin {
      margin: 0 3px 0 3px !important;
      font-size: 8px !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .logo-mobile {
      height: 30px !important;
    }
  }

  @media (max-width: 325px) {
    .mobile-margin {
      margin: 0 1px 0 1px !important;
      font-size: 7.5px !important;
    }
  }

/***** <= 1000px *****/
@media (min-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}
@media only screen and (max-width: 1000px) {
    .respond-max {
        display: none;
    }

    .respond-card {
        width: 80%;
        height: 300px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .respond-resource {
        width: "100%"
    }
    .respond-row-column {
        flex-direction: column;
    }
}