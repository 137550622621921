.ag-theme-quartz .ag-row-hover{
    cursor: pointer;
}

.ag-row {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.more-info-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 3vh;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.posting-page {
    padding: -20px;;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.filter-name {
    font-size: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    margin: 1vw;
}

.filter-box {
    width: auto;
    height: 1.5vh;
}

.filter-groups {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 1.8vw;
    top: 0;
    left: 0;
}

.filter-group {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: left; 
    justify-content: center;
    margin: 2vw 0;
}

.job-postings {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 5vw;
}

.job-posting {
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1vw;
    margin: 1vw 0;
    margin-top: 4vh;
    max-width: 60vw;
    transition: transform 0.2s ease-in-out;
    border-radius: 10px;
    display: block;
}

.job-posting:hover {
    transform: scale(1.007);
}

.job-title-type {
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
}

.job-title {
    font-size: 24px;
    font-weight: bold;
    color:  #8B0000;
}

.job-type {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    color:  #f4f4f4;
    background-color: #8B0000;
    border-radius: 10px;
}

.company-name {
    font-size: 22px;
    font-weight: bold;
    color: #DAA520;
}

.job-description, .job-reqs, .job-location, .contact, .date-details {
    margin-top: 10px;
}

.date-details {
    font-style: italic;
}