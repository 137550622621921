/***** Always True *****/
@media only screen {
  body {
    font-family: proxima-nova, sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html, body, #appRoot, #root {
    scrollbar-width: none;
    -ms-overflow-style: none; 
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    /* background-color: #161616; */
    overflow: scroll;
  }

  #appRoot {
    display: flex;
    overflow: auto;
  }

  ::-webkit-scrollbar {
      width: 0; 
      background: transparent; 
  }
}

@media only screen and (min-width: 700px) {
}

/***** <= 700px *****/
@media only screen and (max-width: 700px) {

}