.h1-mobile {
    margin-bottom: 150px;
  }
  
  @media (min-width: 768px) {
    .h1-mobile {
      margin-bottom: 30px;
    }
  }

  .h1-mobile-offcampus {
    margin-bottom: 150px;
  }

  @media (min-width: 768px) {
    .h1-mobile-offcampus {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

.section-mobile {
  background-color: white;
  color: white;
  width: 100%;
}

@media (max-width: 767px) {
  .section-mobile {
    margin-bottom: 20%;
  }
}